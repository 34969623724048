/* eslint-disable */
export default class FatturaElettronica {
    constructor(
            { 
                FatturaElettronicaHeaderDatiTrasmissioneIdTrasmittenteIdPaese,
                FatturaElettronicaHeaderDatiTrasmissioneIdTrasmittenteIdCodice,
                FatturaElettronicaHeaderDatiTrasmissioneProgressivoInvio,
                FatturaElettronicaHeaderDatiTrasmissioneFormatoTrasmissione,
                FatturaElettronicaHeaderDatiTrasmissioneCodiceDestinatario,
                FatturaElettronicaHeaderDatiTrasmissioneContattiTrasmittente,
            },
            {
                FatturaElettronicaHeaderCedentePrestatoreDatiAnagraficiIdFiscaleIVAIdPaese,
                FatturaElettronicaHeaderCedentePrestatoreDatiAnagraficiIdFiscaleIVAIdCodice,
                FatturaElettronicaHeaderCedentePrestatoreDatiAnagraficiAnagraficaDenominazione,
                FatturaElettronicaHeaderCedentePrestatoreDatiAnagraficiRegimeFiscale,
            },
            {
                FatturaElettronicaHeaderCedentePrestatoreSedeIndirizzo,
                FatturaElettronicaHeaderCedentePrestatoreSedeCAP,
                FatturaElettronicaHeaderCedentePrestatoreSedeComune,
                FatturaElettronicaHeaderCedentePrestatoreSedeProvincia,
                FatturaElettronicaHeaderCedentePrestatoreSedeNazione,
            },
            {
                FatturaElettronicaHeaderCessionarioCommittenteDatiAnagraficiCodiceFiscale,
                FatturaElettronicaHeaderCessionarioCommittenteDatiAnagraficiAnagraficaDenominazione,
                FatturaElettronicaHeaderCessionarioCommittenteSedeIndirizzo,
                FatturaElettronicaHeaderCessionarioCommittenteSedeCAP,
                FatturaElettronicaHeaderCessionarioCommittenteSedeComune,
                FatturaElettronicaHeaderCessionarioCommittenteSedeProvincia,
                FatturaElettronicaHeaderCessionarioCommittenteSedeNazione,
            },
            
            {
              FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoTipoDocumento,
              FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoDivisa,
              FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoData,
              FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoNumero,
              FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoCausale
            },
            { 
              FatturaElettronicaBodyDatiGeneraliDatiOrdineAcquistoRiferimentoNumeroLinea, 
              FatturaElettronicaBodyDatiGeneraliDatiOrdineAcquistoIdDocumento, 
              FatturaElettronicaBodyDatiGeneraliDatiOrdineAcquistoNumItem, 
            },
            { 
              FatturaElettronicaBodyDatiGeneraliDatiTrasportoDatiAnagraficiVettoreIdFiscaleIVAIdPaese,
              FatturaElettronicaBodyDatiGeneraliDatiTrasportoDatiAnagraficiVettoreIdFiscaleIVAIdCodice,
              FatturaElettronicaBodyDatiGeneraliDatiTrasportoDatiAnagraficiVettoreAnagraficaDenominazione,
              FatturaElettronicaBodyDatiGeneraliDatiTrasportoDataOraConsegna
            },
            {
              FatturaElettronicaBodyDatiBeniServiziDettaglioLinee,
              FatturaElettronicaBodyDatiBeniServiziDatiRiepilogoAliquotaIVA,
              FatturaElettronicaBodyDatiBeniServiziDatiRiepilogoImponibileImporto,
              FatturaElettronicaBodyDatiBeniServiziDatiRiepilogoImposta,
              FatturaElettronicaBodyDatiBeniServiziDatiRiepilogoEsigibilitaIVA,
            },
            {
              FatturaElettronicaBodyDatiPagamentoCondizioniPagamento,
              FatturaElettronicaBodyDatiPagamentoDettaglioPagamentoModalitaPagamento,
              FatturaElettronicaBodyDatiPagamentoDettaglioPagamentoDataScadenzaPagamento,
              FatturaElettronicaBodyDatiPagamentoDettaglioPagamentoImportoPagamento,
            }
        ) {
        this.FatturaElettronicaHeader =  {
            DatiTrasmissione: {
                IdTrasmittente: {
                    IdPaese: FatturaElettronicaHeaderDatiTrasmissioneIdTrasmittenteIdPaese,                    
                    IdCodice: FatturaElettronicaHeaderDatiTrasmissioneIdTrasmittenteIdCodice,
                },
                ProgressivoInvio: FatturaElettronicaHeaderDatiTrasmissioneProgressivoInvio,
                FormatoTrasmissione: FatturaElettronicaHeaderDatiTrasmissioneFormatoTrasmissione,
                CodiceDestinatario: FatturaElettronicaHeaderDatiTrasmissioneCodiceDestinatario,
                ContattiTrasmittente: FatturaElettronicaHeaderDatiTrasmissioneContattiTrasmittente
            },
            CedentePrestatore: {
                DatiAnagrafici: {
                    IdFiscaleIVA: {
                        IdPaese: FatturaElettronicaHeaderCedentePrestatoreDatiAnagraficiIdFiscaleIVAIdPaese,
                        IdCodice: FatturaElettronicaHeaderCedentePrestatoreDatiAnagraficiIdFiscaleIVAIdCodice
                    },               
                    Anagrafica: {
                        Denominazione: FatturaElettronicaHeaderCedentePrestatoreDatiAnagraficiAnagraficaDenominazione,
                    },
                    RegimeFiscale: FatturaElettronicaHeaderCedentePrestatoreDatiAnagraficiRegimeFiscale,
                },
                Sede: {
                    Indirizzo: FatturaElettronicaHeaderCedentePrestatoreSedeIndirizzo,
                    CAP: FatturaElettronicaHeaderCedentePrestatoreSedeCAP,
                    Comune: FatturaElettronicaHeaderCedentePrestatoreSedeComune,
                    Provincia: FatturaElettronicaHeaderCedentePrestatoreSedeProvincia,
                    Nazione: FatturaElettronicaHeaderCedentePrestatoreSedeNazione,
                }
            },
            CessionarioCommittente: {
                DatiAnagrafici: {
                    CodiceFiscale: FatturaElettronicaHeaderCessionarioCommittenteDatiAnagraficiCodiceFiscale,
                    Anagrafica: {
                        Denominazione: FatturaElettronicaHeaderCessionarioCommittenteDatiAnagraficiAnagraficaDenominazione,
                    }
                },
                Sede: {
                    Indirizzo: FatturaElettronicaHeaderCessionarioCommittenteSedeIndirizzo,
                    CAP: FatturaElettronicaHeaderCessionarioCommittenteSedeCAP,
                    Comune: FatturaElettronicaHeaderCessionarioCommittenteSedeComune,
                    Provincia: FatturaElettronicaHeaderCessionarioCommittenteSedeProvincia,
                    Nazione: FatturaElettronicaHeaderCessionarioCommittenteSedeNazione,
                }
            }
        },
        this.FatturaElettronicaBody = {
            DatiGenerali: {
                DatiGeneraliDocumento: {
                    TipoDocumento: FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoTipoDocumento,
                    Divisa: FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoDivisa,
                    Data: FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoData,
                    Numero: FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoNumero,
                    Causale: FatturaElettronicaBodyDatiGeneraliDatiGeneraliDocumentoCausale,
                },
                DatiOrdineAcquisto: {
                    RiferimentoNumeroLinea: FatturaElettronicaBodyDatiGeneraliDatiOrdineAcquistoRiferimentoNumeroLinea,
                    IdDocumento: FatturaElettronicaBodyDatiGeneraliDatiOrdineAcquistoIdDocumento,
                    NumItem: FatturaElettronicaBodyDatiGeneraliDatiOrdineAcquistoNumItem,
                },
                DatiTrasporto: {
                    DatiAnagraficiVettore: {
                        IdFiscaleIVA: {
                            IdPaese: FatturaElettronicaBodyDatiGeneraliDatiTrasportoDatiAnagraficiVettoreIdFiscaleIVAIdPaese,
                            IdCodice: FatturaElettronicaBodyDatiGeneraliDatiTrasportoDatiAnagraficiVettoreIdFiscaleIVAIdCodice,
                        },
                        Anagrafica: {
                            Denominazione: FatturaElettronicaBodyDatiGeneraliDatiTrasportoDatiAnagraficiVettoreAnagraficaDenominazione
                        }
                    },
                    DataOraConsegna: FatturaElettronicaBodyDatiGeneraliDatiTrasportoDataOraConsegna
                }
            },
            DatiBeniServizi: {
              DettaglioLinee: FatturaElettronicaBodyDatiBeniServiziDettaglioLinee.map(({ NumeroLinea, Descrizione, Quantita, PrezzoUnitario, PrezzoTotale, AliquotaIVA }) => ({ NumeroLinea, Descrizione, Quantita, PrezzoUnitario, PrezzoTotale, AliquotaIVA })),
              DatiRiepilogo: {
                AliquotaIVA: FatturaElettronicaBodyDatiBeniServiziDatiRiepilogoAliquotaIVA,
                ImponibileImporto: FatturaElettronicaBodyDatiBeniServiziDatiRiepilogoImponibileImporto,
                Imposta: FatturaElettronicaBodyDatiBeniServiziDatiRiepilogoImposta,
                EsigibilitaIVA: FatturaElettronicaBodyDatiBeniServiziDatiRiepilogoEsigibilitaIVA,
              }
            },
            DatiPagamento: {
              CondizioniPagamento: FatturaElettronicaBodyDatiPagamentoCondizioniPagamento,
              DettaglioPagamento: {
                ModalitaPagamento: FatturaElettronicaBodyDatiPagamentoDettaglioPagamentoModalitaPagamento,
                DataScadenzaPagamento: FatturaElettronicaBodyDatiPagamentoDettaglioPagamentoDataScadenzaPagamento,
                ImportoPagamento: FatturaElettronicaBodyDatiPagamentoDettaglioPagamentoImportoPagamento,
              }
            }
        }
    }
  }
  /* "FatturaElettronica": {
    "FatturaElettronicaHeader": {
      "DatiTrasmissione": {
        "IdTrasmittente": {
          "IdPaese": "IT",
          "IdCodice": 1234567890
        },
        "ProgressivoInvio": 1,
        "FormatoTrasmissione": "FPR12",
        "CodiceDestinatario": "ABC1234",
        "ContattiTrasmittente": ""
      },
      "CedentePrestatore": {
        "DatiAnagrafici": {
          "IdFiscaleIVA": {
            "IdPaese": "IT",
            "IdCodice": 1234567890
          },
          "Anagrafica": {
            "Denominazione": "SOCIETA' ALPHA SRL"
          },
          "RegimeFiscale": "RF01"
        },
        "Sede": {
          "Indirizzo": "VIALE ROMA 543",
          "CAP": 7100,
          "Comune": "SASSARI",
          "Provincia": "SS",
          "Nazione": "IT"
        }
      },
      "CessionarioCommittente": {
        "DatiAnagrafici": {
          "CodiceFiscale": 9876543210,
          "Anagrafica": {
            "Denominazione": "BETA GAMMA"
          }
        },
        "Sede": {
          "Indirizzo": "VIA TORINO 38-B",
          "CAP": 145,
          "Comune": "ROMA",
          "Provincia": "RM",
          "Nazione": "IT"
        }
      }
    },
    "FatturaElettronicaBody": {
      "DatiGenerali": {
        "DatiGeneraliDocumento": {
          "TipoDocumento": "TD01",
          "Divisa": "EUR",
          "Data": "2014-12-18",
          "Numero": 123,
          "Causale": [
            "LA FATTURA FA RIFERIMENTO AD UNA OPERAZIONE AAAA BBBBBBBBBBBBBBBBBB CCC DDDDDDDDDDDDDDD E FFFFFFFFFFFFFFFFFFFF GGGGGGGGGG HHHHHHH II LLLLLLLLLLLLLLLLL MMM NNNNN OO PPPPPPPPPPP QQQQ RRRR SSSSSSSSSSSSSS",
            "SEGUE DESCRIZIONE CAUSALE NEL CASO IN CUI NON SIANO STATI SUFFICIENTI 200 CARATTERI AAAAAAAAAAA BBBBBBBBBBBBBBBBB"
          ]
        },
        "DatiOrdineAcquisto": {
          "RiferimentoNumeroLinea": 1,
          "IdDocumento": 66685,
          "NumItem": 1
        },
        "DatiTrasporto": {
          "DatiAnagraficiVettore": {
            "IdFiscaleIVA": {
              "IdPaese": "IT",
              "IdCodice": 24681012141
            },
            "Anagrafica": {
              "Denominazione": "Trasporto spa"
            }
          },
          "DataOraConsegna": "2012-10-22T16:46:12.000+02:00"
        }
      },
      "DatiBeniServizi": {
        "DettaglioLinee": [
          {
            "NumeroLinea": 1,
            "Descrizione": "LA DESCRIZIONE DELLA FORNITURA PUO' SUPERARE I CENTO CARATTERI CHE RAPPRESENTAVANO IL PRECEDENTE LIMITE DIMENSIONALE. TALE LIMITE NELLA NUOVA VERSIONE E' STATO PORTATO A MILLE CARATTERI",
            "Quantita": 5,
            "PrezzoUnitario": 1,
            "PrezzoTotale": 5,
            "AliquotaIVA": 22
          },
          {
            "NumeroLinea": 2,
            "Descrizione": "FORNITURE VARIE PER UFFICIO",
            "Quantita": 10,
            "PrezzoUnitario": 2,
            "PrezzoTotale": 20,
            "AliquotaIVA": 22
          }
        ],
        "DatiRiepilogo": {
          "AliquotaIVA": 22,
          "ImponibileImporto": 27,
          "Imposta": 5.95,
          "EsigibilitaIVA": "I"
        }
      },
      "DatiPagamento": {
        "CondizioniPagamento": "TP01",
        "DettaglioPagamento": {
          "ModalitaPagamento": "MP01",
          "DataScadenzaPagamento": "2015-01-30",
          "ImportoPagamento": 32.95
        }
      }
    }
  } */